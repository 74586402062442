import { injectable } from "tsyringe"
import { PusatResolusiRepository } from "@/app/infrastructures/repositories/api/PusatResolusiRepository";
import { PusatResolusiList, PusatResolusiStatusData, PusatResolusiCategoryData, CheckValidationData, DetailPusatResolusi, PusatResolusiModalStatusData, DetailForumPusatResolusi, ForumPusatResolusiList } from "@/domain/entities/PusatResolusi";
import { PusatResolusiListRequest, CheckValidationRequest, CreatePusatResolusiRequest, ModalStatusRequest, CreateForumPusatResolusiRequest, ForumPusatResolusiListRequest, PusatResolusiStatusRequest } from "@/data/payload/api/PusatResolusiApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
@injectable()
export class PusatResolusiPresenter {
  private repository: PusatResolusiRepository;

  constructor(repo: PusatResolusiRepository) {
    this.repository = repo;
  }

  getPusatResolusiList(
    params: PusatResolusiListRequest
  ): Promise<PusatResolusiList> {
    return this.repository.getPusatResolusiList(params);
  }

  getPusatResolusiStatus(params: PusatResolusiStatusRequest): Promise<PusatResolusiStatusData> {
    return this.repository.getPusatResolusiStatus(params);
  }

  getCategories(): Promise<PusatResolusiCategoryData> {
    return this.repository.getCategories();
  }

  checkValidationStatus(
    params: CheckValidationRequest
  ): Promise<CheckValidationData> {
    return this.repository.checkValidationStatus(params);
  }

  getModalStatusPresenter(
    params: ModalStatusRequest
  ): Promise<PusatResolusiModalStatusData> {
    return this.repository.getModalStatusPusatResolusi(params);
  }

  createPusatResolusi(
    params: CreatePusatResolusiRequest
  ): Promise<ResponsePayload> {
    return this.repository.createPusatResolusi(params);
  }

  getDetailPusatResolusi(ticketId: string): Promise<DetailPusatResolusi> {
    return this.repository.getDetailPusatResolusi(ticketId);
  }

  createForumPusatResolusi(
    params: CreateForumPusatResolusiRequest
  ): Promise<ResponsePayload> {
    return this.repository.createForumPusatResolusi(params);
  }

  getDetailForumPusatResolusi(id: string): Promise<ForumPusatResolusiList> {
    return this.repository.getDetailForumPusatResolusi(id);
  }

}
