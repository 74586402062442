
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options, prop } from "vue-class-component";
import {
  formatDate,
} from "@/app/infrastructures/misc/Utils";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import UploadImage from "@/app/ui/components/upload-image/index.vue";
import { PusatResolusiController } from "@/app/ui/controllers/PusatResolusiController";
import { ModalStatusRequest } from "@/data/payload/api/PusatResolusiApiRequest";
import ProfileGray from "@/app/ui/components/lp-icon/svgs/profile-gray.vue";
import LocationGray from "@/app/ui/components/lp-icon/svgs/location-gray.vue";
import TaskWhite from "@/app/ui/components/lp-icon/svgs/task-white.vue";
import SendWhite from "@/app/ui/components/lp-icon/svgs/send-white.vue";
import { PusatResolusiModalStatusData } from "@/domain/entities/PusatResolusi";

class Props {
  fontSize = prop<number>({
    default: 12,
    type: Number
  });
  noTicket = prop<string>({
    type: String
  });
}

@Options({
  components: {
    Title,
    UploadImage,
    ProfileGray,
    LocationGray,
    TaskWhite,
    SendWhite
  }
})
export default class ModalStatusPusatResolusi extends Vue.with(Props) {

  formatDate(date: string) {
    return formatDate(date).replace(",", " -");
  }

  handleStatusMsg(status: string, name: string) {
    let message = "";
    switch (status) {
        case "new":
        message = "Laporan kendala telah dikirim";
        break;
        case "in_handling":
        message = `Laporan kendala dalam penanganan (Nama CS: ${name})`;
        break;
        default:
        break;
    }
    return message
  }

  get modalStatus() {
    const isShow = PusatResolusiController.getModalStatus;
    if(isShow) {
      this.getDetail();
    }
    return isShow;
  }

  onClose() {
    PusatResolusiController.setModalStatus(false);
  }
  
  detailData = new PusatResolusiModalStatusData();
  async getDetail() {
    this.detailData =  await PusatResolusiController.modalStatusController(new ModalStatusRequest({
      ticketNoSf: this.noTicket
    }));
  }
}
