import {
  getModule,
  Module,
  Action,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import {
  PusatResolusiCardData,
  PusatResolusiCommentData,
  PusatResolusiThreadData,
  PusatResolusiModalStatusData
} from "@/domain/entities/PusatResolusi";
import { MainAppController } from "./MainAppController";
import { PusatResolusiListRequest, CheckValidationRequest, CreatePusatResolusiRequest, ModalStatusRequest, CreateForumPusatResolusiRequest, PusatResolusiStatusRequest } from "@/data/payload/api/PusatResolusiApiRequest";
import { PusatResolusiPresenter } from "@/app/ui/presenters/PusatResolusiPresenter";
import { PusatResolusiComponent } from "@/app/infrastructures/dependencies/modules/PusatResolusiComponent";

PusatResolusiComponent.init();
export interface PusatResolusiState {
  loading: boolean;
  error: string;
  sttNo: string;
  noTicket: string;
  modalStatus: boolean;
}
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "pusatResolusi"
})
class PusatResolusiStore extends VuexModule implements PusatResolusiState {
  public loading = false;
  public modalStatus = false;
  public error = "";
  public sttNo = "";
  public noTicket = "";
  public pusatResolusiDetailData: PusatResolusiCardData[] = Array(101)
    .fill("")
    .map(
      (_, i) =>
        new PusatResolusiCardData({
          salesForId: `SF/${i}578${i + 1}${i + 2}/POS/7${i + 1}547004${i +
            5}${i + 6}`,
          sttNo: `99LP${Math.round(
            Math.random() * (9999999999999 - 1111111111111)
          )}`,
          title: "Pengajuan Pengembalian Barang dan Dana",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
          posName: "POS Pal Merah",
          createdAt: new Date().toISOString(),
          createdBy: "Fuad Rifki",
          updatedAt: new Date().toISOString(),
          updatedBy: "Rifki Fuad",
          status:
            i % 5 === 0
              ? "not-responded-yet"
              : i % 5 === 2
              ? "in-handling"
              : "done",
          isRead: i % 4 !== 2,
          attachmentImage: Array(5)
            .fill("")
            .map((_, i) =>
              i % 2 === 0
                ? "http://www.harnas.co/files/images/760420/2020/05/22/tips-aman-dan-nyaman-kirimkan-paket-di-masa-pandemi.jpg"
                : "https://foto.wartaekonomi.co.id/files/arsip_foto_2019_07_27/pt_lion_express_lion_parcel_151850_big.webp"
            ),
          attachmentFile: Array(2)
            .fill("")
            .map(() => ({
              name: "dummyDummyDummyDummy.pdf",
              url:
                "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
            }))
        })
    );

  public form = {
    stt: "",
    shipment: "",
    category: {
      name: "",
      value: ""
    },
    description: "",
    attachmentImage: [],
    attachmentFile: []
  };

  public formForum = {
    caseId: "" as any,
    message: "",
    attachmentImage: [] as any,
    attachmentFile: [] as any
  };

  public thread = new PusatResolusiThreadData();

  public dummyThreads = Array(10)
    .fill("")
    .map((_, index) => {
      const random = Math.random() > 0.5;
      return new PusatResolusiThreadData({
        id: index,
        actorName: random ? "POS AMBON - PT. MATRAS" : "Silvia Anwar",
        actorType: random ? "partner" : "admin",
        text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus ipsa nisi doloribus maiores modi quidem dignissimos debitis corrupti perferendis odit?${index}`,
        attachmentImage: Array(5).fill(
          "https://media-cldnry.s-nbcnews.com/image/upload/newscms/2020_13/1551179/package-delivery-safety-today-main-200323.jpg"
        ),
        attachmentFile: [
          {
            name: "fileUpload_filename.pdf",
            url: "http://pii.or.id/uploads/dummies.pdf"
          }
        ],
        comments: Array(10)
          .fill("")
          .map((__, index) => {
            const random = Math.random() > 0.5;
            return new PusatResolusiCommentData({
              id: index,
              actorName: random ? "POS AMBON - PT. MATRAS" : "Silvia Anwar",
              actorType: random ? "partner" : "admin",
              createdAt: new Date().toISOString(),
              text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus ipsa nisi doloribus maiores modi quidem dignissimos debitis corrupti perferendis odit?${index}`
            });
          }),
        createdAt: new Date().toISOString()
      });
    });

  public modalStatusData = new PusatResolusiModalStatusData();

  @Action
  async onSubmitComment() {
    MainAppController.showLoading();
    return new Promise(r =>
      setTimeout(() => {
        r(MainAppController.closeLoading());
      }, 500)
    );
  }

  @Action
  public getPusatResolusiList(params: PusatResolusiListRequest) {
    const presenter = container.resolve(PusatResolusiPresenter);
    return presenter.getPusatResolusiList(params);
  }
  
  @Action
  public getPusatResolusiStatus(params: PusatResolusiStatusRequest) {
    const presenter = container.resolve(PusatResolusiPresenter);
    return presenter.getPusatResolusiStatus(params);
  }

  @Action
  public getCategories() {
    const presenter = container.resolve(PusatResolusiPresenter);
    return presenter.getCategories();
  }

  @Action
  public checkValidationStatus(params: CheckValidationRequest) {
    const presenter = container.resolve(PusatResolusiPresenter);
    return presenter.checkValidationStatus(params);
  }

  @Action
  public modalStatusController(params: ModalStatusRequest) {
    const presenter = container.resolve(PusatResolusiPresenter);
    return presenter.getModalStatusPresenter(params);
  }

  @Action
  public createPusatResolusi(params: CreatePusatResolusiRequest) {
    const presenter = container.resolve(PusatResolusiPresenter);
    return presenter.createPusatResolusi(params);
  }

  @Action
  public createForumPusatResolusi(params: CreateForumPusatResolusiRequest) {
    const presenter = container.resolve(PusatResolusiPresenter);
    return presenter.createForumPusatResolusi(params);
  }

  @Action
  public getDetailPusatResolusi(ticketId: string) {
    const presenter = container.resolve(PusatResolusiPresenter);
    return presenter.getDetailPusatResolusi(ticketId);
  }

  @Action
  public getDetailForumPusatResolusi(id: string) {
    const presenter = container.resolve(PusatResolusiPresenter);
    return presenter.getDetailForumPusatResolusi(id);
  }

  @Action
  public _setSttNo(payload: any) {
    this.setSttNo(payload);
  }

  @Mutation
  public onResetThread() {
    this.thread = new PusatResolusiThreadData();
  }

  @Mutation
  resetForm() {
    this.form = {
      stt: "",
      shipment: "",
      category: {
        name: "",
        value: ""
      },
      description: "",
      attachmentImage: [],
      attachmentFile: []
    };
  }

  @Mutation
  public setSttNo(value: string) {
    this.sttNo = value;
  }

  get getSttNo() {
    return this.sttNo
  }

  @Mutation
  public setNoTicket(value: string) {
    this.noTicket = value;
  }

  get getNoTicket() {
    return this.noTicket
  }

  @Mutation
  public setModalStatus(bool: boolean) {
    this.modalStatus = bool;
  }

  get getModalStatus() {
    return this.modalStatus
  }
}

export const PusatResolusiController = getModule(PusatResolusiStore);
