import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowDown = _resolveComponent("ArrowDown")!
  const _component_ArrowUp = _resolveComponent("ArrowUp")!
  const _component_Attachment = _resolveComponent("Attachment")!
  const _component_Info = _resolveComponent("Info")!
  const _component_CargoPlane = _resolveComponent("CargoPlane")!
  const _component_CargoTruck = _resolveComponent("CargoTruck")!
  const _component_CargoTrain = _resolveComponent("CargoTrain")!
  const _component_CloudUpload = _resolveComponent("CloudUpload")!
  const _component_Transit = _resolveComponent("Transit")!
  const _component_Misroute = _resolveComponent("Misroute")!
  const _component_Shortland = _resolveComponent("Shortland")!
  const _component_StiDest = _resolveComponent("StiDest")!
  const _component_StiDestSc = _resolveComponent("StiDestSc")!
  const _component_Delivery = _resolveComponent("Delivery")!
  const _component_Dex = _resolveComponent("Dex")!
  const _component_PodDex = _resolveComponent("PodDex")!
  const _component_Ellipsis = _resolveComponent("Ellipsis")!
  const _component_FileChart = _resolveComponent("FileChart")!
  const _component_FileDownload = _resolveComponent("FileDownload")!
  const _component_Bkd = _resolveComponent("Bkd")!
  const _component_Shipment = _resolveComponent("Shipment")!
  const _component_StiSc = _resolveComponent("StiSc")!
  const _component_Sti = _resolveComponent("Sti")!
  const _component_Bagging = _resolveComponent("Bagging")!
  const _component_Cargo = _resolveComponent("Cargo")!
  const _component_WhiteTrash = _resolveComponent("WhiteTrash")!
  const _component_GreyPlus = _resolveComponent("GreyPlus")!
  const _component_IconX = _resolveComponent("IconX")!
  const _component_CycleTime = _resolveComponent("CycleTime")!
  const _component_Reload = _resolveComponent("Reload")!
  const _component_AutoCopy = _resolveComponent("AutoCopy")!
  const _component_ChevronUpDown = _resolveComponent("ChevronUpDown")!
  const _component_ChevronDownUp = _resolveComponent("ChevronDownUp")!
  const _component_TurnLeft = _resolveComponent("TurnLeft")!
  const _component_PrintSummary = _resolveComponent("PrintSummary")!
  const _component_Printer = _resolveComponent("Printer")!
  const _component_CheckCircle = _resolveComponent("CheckCircle")!
  const _component_Hal = _resolveComponent("Hal")!
  const _component_Rejected = _resolveComponent("Rejected")!
  const _component_Eye = _resolveComponent("Eye")!
  const _component_Invisible = _resolveComponent("Invisible")!
  const _component_XCircle = _resolveComponent("XCircle")!
  const _component_ShortcutBookingManual = _resolveComponent("ShortcutBookingManual")!
  const _component_ShortcutBookingClient = _resolveComponent("ShortcutBookingClient")!
  const _component_ShortcutBookingShipment = _resolveComponent("ShortcutBookingShipment")!
  const _component_ShortcutBookingInstant = _resolveComponent("ShortcutBookingInstant")!
  const _component_ShortcutBookingPpob = _resolveComponent("ShortcutBookingPpob")!
  const _component_BkdToSti = _resolveComponent("BkdToSti")!
  const _component_BookingRetail = _resolveComponent("BookingRetail")!
  const _component_BookingClient = _resolveComponent("BookingClient")!
  const _component_BookingApp = _resolveComponent("BookingApp")!
  const _component_BookingInstant = _resolveComponent("BookingInstant")!
  const _component_CustomProcess = _resolveComponent("CustomProcess")!
  const _component_Exclamation = _resolveComponent("Exclamation")!
  const _component_OutgoingCurrentStt = _resolveComponent("OutgoingCurrentStt")!
  const _component_IncomingCurrentStt = _resolveComponent("IncomingCurrentStt")!
  const _component_Timer = _resolveComponent("Timer")!
  const _component_InputManifestDashboard = _resolveComponent("InputManifestDashboard")!
  const _component_InvalidDashboard = _resolveComponent("InvalidDashboard")!
  const _component_IncomingOutgoing = _resolveComponent("IncomingOutgoing")!
  const _component_Backlog = _resolveComponent("Backlog")!
  const _component_Hanging = _resolveComponent("Hanging")!
  const _component_Whatsapp = _resolveComponent("Whatsapp")!
  const _component_Ascending = _resolveComponent("Ascending")!
  const _component_Descending = _resolveComponent("Descending")!
  const _component_STTPaidUnpaid = _resolveComponent("STTPaidUnpaid")!
  const _component_ProgressiveCommission = _resolveComponent("ProgressiveCommission")!
  const _component_DownloadNew = _resolveComponent("DownloadNew")!
  const _component_CodrejDex = _resolveComponent("CodrejDex")!
  const _component_Check = _resolveComponent("Check")!
  const _component_Lock = _resolveComponent("Lock")!
  const _component_Clock = _resolveComponent("Clock")!
  const _component_PlusCircle = _resolveComponent("PlusCircle")!
  const _component_Ship = _resolveComponent("Ship")!
  const _component_BillAdd = _resolveComponent("BillAdd")!
  const _component_ClaimPlus = _resolveComponent("ClaimPlus")!
  const _component_CheckWhite = _resolveComponent("CheckWhite")!
  const _component_ExclamationWhite = _resolveComponent("ExclamationWhite")!
  const _component_ShieldWhite = _resolveComponent("ShieldWhite")!
  const _component_TaskWhite = _resolveComponent("TaskWhite")!
  const _component_TimeWhite = _resolveComponent("TimeWhite")!
  const _component_XWhite = _resolveComponent("XWhite")!
  const _component_PictureFrame = _resolveComponent("PictureFrame")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_Track = _resolveComponent("Track")!
  const _component_CargoConfiguration = _resolveComponent("CargoConfiguration")!
  const _component_PlusSquare = _resolveComponent("PlusSquare")!
  const _component_Plane = _resolveComponent("Plane")!
  const _component_TrashConcentrated = _resolveComponent("TrashConcentrated")!
  const _component_WarningCircle = _resolveComponent("WarningCircle")!
  const _component_PencilEdit = _resolveComponent("PencilEdit")!
  const _component_WhatsAppOutline = _resolveComponent("WhatsAppOutline")!
  const _component_Chain = _resolveComponent("Chain")!
  const _component_IconProfile = _resolveComponent("IconProfile")!
  const _component_IconLocationPin = _resolveComponent("IconLocationPin")!

  return (_ctx.name === 'arrow-down')
    ? (_openBlock(), _createBlock(_component_ArrowDown, { key: 0 }))
    : (_ctx.name === 'arrow-up')
      ? (_openBlock(), _createBlock(_component_ArrowUp, { key: 1 }))
      : (_ctx.name === 'attachment')
        ? (_openBlock(), _createBlock(_component_Attachment, { key: 2 }))
        : (_ctx.name === 'info')
          ? (_openBlock(), _createBlock(_component_Info, { key: 3 }))
          : (_ctx.name === 'cargo-plane')
            ? (_openBlock(), _createBlock(_component_CargoPlane, {
                key: 4,
                fill: _ctx.fill
              }, null, 8, ["fill"]))
            : (_ctx.name === 'cargo-truck')
              ? (_openBlock(), _createBlock(_component_CargoTruck, { key: 5 }))
              : (_ctx.name === 'cargo-train')
                ? (_openBlock(), _createBlock(_component_CargoTrain, {
                    key: 6,
                    fill: _ctx.fill
                  }, null, 8, ["fill"]))
                : (_ctx.name === 'cloud-upload')
                  ? (_openBlock(), _createBlock(_component_CloudUpload, { key: 7 }))
                  : (_ctx.name === 'transit')
                    ? (_openBlock(), _createBlock(_component_Transit, { key: 8 }))
                    : (_ctx.name === 'misroute')
                      ? (_openBlock(), _createBlock(_component_Misroute, { key: 9 }))
                      : (_ctx.name === 'shortland')
                        ? (_openBlock(), _createBlock(_component_Shortland, { key: 10 }))
                        : (_ctx.name === 'sti-dest')
                          ? (_openBlock(), _createBlock(_component_StiDest, { key: 11 }))
                          : (_ctx.name === 'sti-dest-sc')
                            ? (_openBlock(), _createBlock(_component_StiDestSc, { key: 12 }))
                            : (_ctx.name === 'delivery')
                              ? (_openBlock(), _createBlock(_component_Delivery, { key: 13 }))
                              : (_ctx.name === 'dex')
                                ? (_openBlock(), _createBlock(_component_Dex, { key: 14 }))
                                : (_ctx.name === 'pod-dex')
                                  ? (_openBlock(), _createBlock(_component_PodDex, { key: 15 }))
                                  : (_ctx.name === 'ellipsis')
                                    ? (_openBlock(), _createBlock(_component_Ellipsis, { key: 16 }))
                                    : (_ctx.name === 'file-chart')
                                      ? (_openBlock(), _createBlock(_component_FileChart, { key: 17 }))
                                      : (_ctx.name === 'file-download')
                                        ? (_openBlock(), _createBlock(_component_FileDownload, { key: 18 }))
                                        : (_ctx.name === 'bkd')
                                          ? (_openBlock(), _createBlock(_component_Bkd, { key: 19 }))
                                          : (_ctx.name === 'shipment')
                                            ? (_openBlock(), _createBlock(_component_Shipment, { key: 20 }))
                                            : (_ctx.name === 'sti-sc')
                                              ? (_openBlock(), _createBlock(_component_StiSc, { key: 21 }))
                                              : (_ctx.name === 'sti')
                                                ? (_openBlock(), _createBlock(_component_Sti, { key: 22 }))
                                                : (_ctx.name === 'bagging')
                                                  ? (_openBlock(), _createBlock(_component_Bagging, { key: 23 }))
                                                  : (_ctx.name === 'cargo')
                                                    ? (_openBlock(), _createBlock(_component_Cargo, { key: 24 }))
                                                    : (_ctx.name === 'white-trash')
                                                      ? (_openBlock(), _createBlock(_component_WhiteTrash, { key: 25 }))
                                                      : (_ctx.name === 'grey-plus')
                                                        ? (_openBlock(), _createBlock(_component_GreyPlus, { key: 26 }))
                                                        : (_ctx.name === 'icon-x')
                                                          ? (_openBlock(), _createBlock(_component_IconX, { key: 27 }))
                                                          : (_ctx.name === 'cycle-time')
                                                            ? (_openBlock(), _createBlock(_component_CycleTime, { key: 28 }))
                                                            : (_ctx.name === 'reload')
                                                              ? (_openBlock(), _createBlock(_component_Reload, { key: 29 }))
                                                              : (_ctx.name === 'auto-copy')
                                                                ? (_openBlock(), _createBlock(_component_AutoCopy, { key: 30 }))
                                                                : (_ctx.name === 'chevron-up-down')
                                                                  ? (_openBlock(), _createBlock(_component_ChevronUpDown, { key: 31 }))
                                                                  : (_ctx.name === 'chevron-down-up')
                                                                    ? (_openBlock(), _createBlock(_component_ChevronDownUp, { key: 32 }))
                                                                    : (_ctx.name === 'turn-left')
                                                                      ? (_openBlock(), _createBlock(_component_TurnLeft, { key: 33 }))
                                                                      : (_ctx.name === 'print-summary')
                                                                        ? (_openBlock(), _createBlock(_component_PrintSummary, { key: 34 }))
                                                                        : (_ctx.name === 'printer')
                                                                          ? (_openBlock(), _createBlock(_component_Printer, { key: 35 }))
                                                                          : (_ctx.name === 'check-circle')
                                                                            ? (_openBlock(), _createBlock(_component_CheckCircle, { key: 36 }))
                                                                            : (_ctx.name === 'hal')
                                                                              ? (_openBlock(), _createBlock(_component_Hal, { key: 37 }))
                                                                              : (_ctx.name === 'rejected')
                                                                                ? (_openBlock(), _createBlock(_component_Rejected, { key: 38 }))
                                                                                : (_ctx.name === 'eye')
                                                                                  ? (_openBlock(), _createBlock(_component_Eye, { key: 39 }))
                                                                                  : (_ctx.name === 'invisible')
                                                                                    ? (_openBlock(), _createBlock(_component_Invisible, { key: 40 }))
                                                                                    : (_ctx.name === 'x-circle')
                                                                                      ? (_openBlock(), _createBlock(_component_XCircle, { key: 41 }))
                                                                                      : (_ctx.name === 'shortcut-booking-manual')
                                                                                        ? (_openBlock(), _createBlock(_component_ShortcutBookingManual, { key: 42 }))
                                                                                        : (_ctx.name === 'shortcut-booking-client')
                                                                                          ? (_openBlock(), _createBlock(_component_ShortcutBookingClient, { key: 43 }))
                                                                                          : (_ctx.name === 'shortcut-booking-shipment')
                                                                                            ? (_openBlock(), _createBlock(_component_ShortcutBookingShipment, { key: 44 }))
                                                                                            : (_ctx.name === 'shortcut-booking-instant')
                                                                                              ? (_openBlock(), _createBlock(_component_ShortcutBookingInstant, { key: 45 }))
                                                                                              : (_ctx.name === 'shortcut-booking-ppob')
                                                                                                ? (_openBlock(), _createBlock(_component_ShortcutBookingPpob, { key: 46 }))
                                                                                                : (_ctx.name === 'bkd-to-sti')
                                                                                                  ? (_openBlock(), _createBlock(_component_BkdToSti, { key: 47 }))
                                                                                                  : (_ctx.name === 'booking-retail')
                                                                                                    ? (_openBlock(), _createBlock(_component_BookingRetail, { key: 48 }))
                                                                                                    : (_ctx.name === 'booking-client')
                                                                                                      ? (_openBlock(), _createBlock(_component_BookingClient, { key: 49 }))
                                                                                                      : (_ctx.name === 'booking-app')
                                                                                                        ? (_openBlock(), _createBlock(_component_BookingApp, { key: 50 }))
                                                                                                        : (_ctx.name === 'booking-instant')
                                                                                                          ? (_openBlock(), _createBlock(_component_BookingInstant, { key: 51 }))
                                                                                                          : (_ctx.name === 'custom-process')
                                                                                                            ? (_openBlock(), _createBlock(_component_CustomProcess, { key: 52 }))
                                                                                                            : (_ctx.name === 'exclamation')
                                                                                                              ? (_openBlock(), _createBlock(_component_Exclamation, {
                                                                                                                  key: 53,
                                                                                                                  fill: _ctx.fill
                                                                                                                }, null, 8, ["fill"]))
                                                                                                              : (_ctx.name === 'outgoing-current-stt')
                                                                                                                ? (_openBlock(), _createBlock(_component_OutgoingCurrentStt, { key: 54 }))
                                                                                                                : (_ctx.name === 'incoming-current-stt')
                                                                                                                  ? (_openBlock(), _createBlock(_component_IncomingCurrentStt, { key: 55 }))
                                                                                                                  : (_ctx.name === 'timer')
                                                                                                                    ? (_openBlock(), _createBlock(_component_Timer, { key: 56 }))
                                                                                                                    : (_ctx.name === 'input-manifest-dashboard')
                                                                                                                      ? (_openBlock(), _createBlock(_component_InputManifestDashboard, {
                                                                                                                          key: 57,
                                                                                                                          status: _ctx.status
                                                                                                                        }, null, 8, ["status"]))
                                                                                                                      : (_ctx.name === 'invalid-dashboard')
                                                                                                                        ? (_openBlock(), _createBlock(_component_InvalidDashboard, { key: 58 }))
                                                                                                                        : (_ctx.name === 'incoming-outgoing')
                                                                                                                          ? (_openBlock(), _createBlock(_component_IncomingOutgoing, {
                                                                                                                              key: 59,
                                                                                                                              fill: _ctx.fill
                                                                                                                            }, null, 8, ["fill"]))
                                                                                                                          : (_ctx.name === 'backlog')
                                                                                                                            ? (_openBlock(), _createBlock(_component_Backlog, {
                                                                                                                                key: 60,
                                                                                                                                fill: _ctx.fill
                                                                                                                              }, null, 8, ["fill"]))
                                                                                                                            : (_ctx.name === 'hanging')
                                                                                                                              ? (_openBlock(), _createBlock(_component_Hanging, {
                                                                                                                                  key: 61,
                                                                                                                                  fill: _ctx.fill
                                                                                                                                }, null, 8, ["fill"]))
                                                                                                                              : (_ctx.name === 'whatsapp')
                                                                                                                                ? (_openBlock(), _createBlock(_component_Whatsapp, { key: 62 }))
                                                                                                                                : (_ctx.name === 'ascending')
                                                                                                                                  ? (_openBlock(), _createBlock(_component_Ascending, { key: 63 }))
                                                                                                                                  : (_ctx.name === 'descending')
                                                                                                                                    ? (_openBlock(), _createBlock(_component_Descending, { key: 64 }))
                                                                                                                                    : (_ctx.name === 'stt-paid-unpaid')
                                                                                                                                      ? (_openBlock(), _createBlock(_component_STTPaidUnpaid, {
                                                                                                                                          key: 65,
                                                                                                                                          fill: _ctx.fill
                                                                                                                                        }, null, 8, ["fill"]))
                                                                                                                                      : (_ctx.name === 'progressive-commission')
                                                                                                                                        ? (_openBlock(), _createBlock(_component_ProgressiveCommission, { key: 66 }))
                                                                                                                                        : (_ctx.name === 'download-new')
                                                                                                                                          ? (_openBlock(), _createBlock(_component_DownloadNew, { key: 67 }))
                                                                                                                                          : (_ctx.name === 'codrej-dex')
                                                                                                                                            ? (_openBlock(), _createBlock(_component_CodrejDex, { key: 68 }))
                                                                                                                                            : (_ctx.name === 'check')
                                                                                                                                              ? (_openBlock(), _createBlock(_component_Check, { key: 69 }))
                                                                                                                                              : (_ctx.name === 'lock')
                                                                                                                                                ? (_openBlock(), _createBlock(_component_Lock, { key: 70 }))
                                                                                                                                                : (_ctx.name === 'clock')
                                                                                                                                                  ? (_openBlock(), _createBlock(_component_Clock, { key: 71 }))
                                                                                                                                                  : (_ctx.name === 'plus-circle')
                                                                                                                                                    ? (_openBlock(), _createBlock(_component_PlusCircle, { key: 72 }))
                                                                                                                                                    : (_ctx.name === 'ship')
                                                                                                                                                      ? (_openBlock(), _createBlock(_component_Ship, { key: 73 }))
                                                                                                                                                      : (_ctx.name === 'bill-add')
                                                                                                                                                        ? (_openBlock(), _createBlock(_component_BillAdd, { key: 74 }))
                                                                                                                                                        : (_ctx.name === 'claim-plus')
                                                                                                                                                          ? (_openBlock(), _createBlock(_component_ClaimPlus, { key: 75 }))
                                                                                                                                                          : (_ctx.name === 'check-white')
                                                                                                                                                            ? (_openBlock(), _createBlock(_component_CheckWhite, { key: 76 }))
                                                                                                                                                            : (_ctx.name === 'exclamation-white')
                                                                                                                                                              ? (_openBlock(), _createBlock(_component_ExclamationWhite, { key: 77 }))
                                                                                                                                                              : (_ctx.name === 'shield-white')
                                                                                                                                                                ? (_openBlock(), _createBlock(_component_ShieldWhite, { key: 78 }))
                                                                                                                                                                : (_ctx.name === 'task-white')
                                                                                                                                                                  ? (_openBlock(), _createBlock(_component_TaskWhite, { key: 79 }))
                                                                                                                                                                  : (_ctx.name === 'time-white')
                                                                                                                                                                    ? (_openBlock(), _createBlock(_component_TimeWhite, { key: 80 }))
                                                                                                                                                                    : (_ctx.name === 'x-white')
                                                                                                                                                                      ? (_openBlock(), _createBlock(_component_XWhite, { key: 81 }))
                                                                                                                                                                      : (_ctx.name === 'cms-banner')
                                                                                                                                                                        ? (_openBlock(), _createBlock(_component_PictureFrame, { key: 82 }))
                                                                                                                                                                        : (_ctx.name === 'delete')
                                                                                                                                                                          ? (_openBlock(), _createBlock(_component_Delete, { key: 83 }))
                                                                                                                                                                          : (_ctx.name === 'track')
                                                                                                                                                                            ? (_openBlock(), _createBlock(_component_Track, { key: 84 }))
                                                                                                                                                                            : (_ctx.name === 'cargo-configuration')
                                                                                                                                                                              ? (_openBlock(), _createBlock(_component_CargoConfiguration, { key: 85 }))
                                                                                                                                                                              : (_ctx.name === 'plus-square')
                                                                                                                                                                                ? (_openBlock(), _createBlock(_component_PlusSquare, { key: 86 }))
                                                                                                                                                                                : (_ctx.name === 'plane')
                                                                                                                                                                                  ? (_openBlock(), _createBlock(_component_Plane, { key: 87 }))
                                                                                                                                                                                  : (_ctx.name === 'trash-concentrated')
                                                                                                                                                                                    ? (_openBlock(), _createBlock(_component_TrashConcentrated, { key: 88 }))
                                                                                                                                                                                    : (_ctx.name === 'warning-circle')
                                                                                                                                                                                      ? (_openBlock(), _createBlock(_component_WarningCircle, { key: 89 }))
                                                                                                                                                                                      : (_ctx.name === 'pencil-edit')
                                                                                                                                                                                        ? (_openBlock(), _createBlock(_component_PencilEdit, { key: 90 }))
                                                                                                                                                                                        : (_ctx.name === 'whatsapp-outline')
                                                                                                                                                                                          ? (_openBlock(), _createBlock(_component_WhatsAppOutline, { key: 91 }))
                                                                                                                                                                                          : (_ctx.name === 'chain')
                                                                                                                                                                                            ? (_openBlock(), _createBlock(_component_Chain, { key: 92 }))
                                                                                                                                                                                            : (_ctx.name === 'icon-profile')
                                                                                                                                                                                              ? (_openBlock(), _createBlock(_component_IconProfile, { key: 93 }))
                                                                                                                                                                                              : (_ctx.name === 'icon-location-pin')
                                                                                                                                                                                                ? (_openBlock(), _createBlock(_component_IconLocationPin, { key: 94 }))
                                                                                                                                                                                                : _createCommentVNode("", true)
}