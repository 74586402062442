import { QueryParamsEntities } from "@/domain/entities/MainApp";
import { PusatResolusiRequestInterface } from "../contracts/PusatResolusiRequest";
import { RequestCreateForumPusatResolusi, RequestCreatePusatResolusi } from "@/domain/entities/PusatResolusi";
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import toFormDataUtils from "@/app/infrastructures/misc/common-library/ToFormDataUtils";
export class PusatResolusiListRequest {
    page = 1;
    limit = 10;
    isTotalData = true;
    search = "";
    createdDateStart = "";
    createdDateEnd = "";
    category = "";
    sort = "";
    status = "";
  
    constructor(fields?: Partial<PusatResolusiListRequest>) {
      Object.assign(this, fields);
    }
  
    toQueryString(): string {
      return new QueryParamsEntities(this).queryString;
    }
}

export class CheckValidationRequest {
  sttOrShipment = "";
  category = "";

  constructor(fields?: Partial<CheckValidationRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class ModalStatusRequest {
  ticketNoSf = "";

  constructor(fields?: Partial<ModalStatusRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class CreatePusatResolusiRequest implements PusatResolusiRequestInterface {
  payload: RequestCreatePusatResolusi = new RequestCreatePusatResolusi();
  constructor(fields?: Partial<RequestCreatePusatResolusi>) {
    Object.assign(this.payload, fields);
  }

  toFormData(): FormData {
    const payload = {
      "category": this.payload.category,
      "stt_or_shipment": this.payload.sttOrShipment,
      "description": this.payload.description,
      "image_file_1": this.payload.imageFile1,
      "image_file_2": this.payload.imageFile2,
      "image_file_3": this.payload.imageFile3,
      "attachment_1": this.payload.attachment1,
      "attachment_2": this.payload.attachment2
    }
    return toFormDataUtils(payload);
  }
}

export class CreateForumPusatResolusiRequest implements PusatResolusiRequestInterface {
  payload: RequestCreateForumPusatResolusi = new RequestCreateForumPusatResolusi();
  constructor(fields?: Partial<RequestCreateForumPusatResolusi>) {
    Object.assign(this.payload, fields);
  }

  toFormData(): FormData {
    const { ...payload } = this.payload;
    return toFormDataUtils(ConvertObjectCamelToSnakeCase(payload));
  }
}

export class ForumPusatResolusiListRequest {
  ticketNoGenesis = "";

  constructor(fields?: Partial<ForumPusatResolusiListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class PusatResolusiStatusRequest {
  search = "";
  createdDateStart = "";
  createdDateEnd = "";
  category = "";

  constructor(fields?: Partial<PusatResolusiStatusRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}


