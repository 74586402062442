
import { PusatResolusiList, PusatResolusiStatus } from "@/domain/entities/PusatResolusi";
import debounce from "lodash/debounce";
import { Options, Vue } from "vue-class-component";
import { AccountController } from "../../controllers/AccountController";
import { PusatResolusiController } from "../../controllers/PusatResolusiController";
import PRCard from "./components/card.vue";
import PaginationV3 from "@/app/ui/components/pagination-v3/index.vue";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
import { PUSAT_RESOLUSI } from "@/app/infrastructures/misc/RolePermission";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";
import { PusatResolusiListRequest, PusatResolusiStatusRequest } from "@/data/payload/api/PusatResolusiApiRequest";
import { Pagination } from "@/domain/entities/Pagination";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { NotificationController } from "../../controllers/NotificationController";
import { getNewPusatResolusi, setNewPusatResolusi } from "@/app/infrastructures/misc/Cookies";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";

@Options({
  components: {
    PRCard,
    PaginationV3
  }
})
export default class PusatResolusiDataList extends Vue {
  created() {
    NotificationController.setIsOpen(true);
    this.assignIsNewPusatResolusi();
  }
  // permission
  get ableToCreate() {
    return checkRolePermission(PUSAT_RESOLUSI.CREATE);
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  assignIsNewPusatResolusi() {
    let newData = [];
    if(getNewPusatResolusi()) {
      if(!JSON.parse(getNewPusatResolusi()).includes(this.dataProfile.account_id)) {
        newData = JSON.parse(getNewPusatResolusi());
        newData.push(this.dataProfile.account_id);
        setNewPusatResolusi(JSON.stringify((newData)))
      }
    } else {
      setNewPusatResolusi(JSON.stringify(([this.dataProfile.account_id])))
    }
  }


  mounted() {
    this.getTabs();
    this.getCategories();
    this.getPusatResolusi();
  }

  activeMenu = "in_handling";
  tabs: any[] = [];

  async getTabs() {
    const getData = await PusatResolusiController.getPusatResolusiStatus(new PusatResolusiStatusRequest({
      search: this.searchValue,
      createdDateStart: this.advancedFilterData.periodeStart,
      createdDateEnd: this.advancedFilterData.periodeEnd,
      category: this.advancedFilterData.category.value
    }));
    this.tabs = getData.data.map((item: PusatResolusiStatus) => {
      return {
        value: item.status,
        title: `${this.tabMap(item.status)} (${item.total})`
      }
    })
  }

  tabMap(value: string) {
    const tabData: any = {
      "in_handling": "Dalam Penanganan",
      "need_reply": "Butuh Balasan Anda",
      "done": "Selesai",
      "all": "Semua"
    }

    return tabData[value];
  }

  categroies: any[] = [];
  async getCategories() {
    const getData = await PusatResolusiController.getCategories();
    const allCategories = [{
      value: "",
      name: "Semua"
    }]
    this.categroies = allCategories.concat(getData.data);
  }
  categoryOpened = false;
  setCategories(item: any) {
    this.advancedFilterData.category = item;
    this.categoryOpened = item.name;
    this.listData.pagination.page = 1;
    this.setFirstRequest(false);
    this.getPusatResolusi();
    this.getTabs();
    this.addSumFilter = 1;
  }

  addSumFilter = 0;
  
  isAbleToAdvanceFilter() {
    return true;
  }
  isAdvancedFilterOpened = false;

  onChangeAdvancedFilter = debounce((event) => {
    this.advancedFilterData.periodeStart = event.periodeStart ? new Date(event.periodeStart).toLocaleDateString("fr-CA") : "";
    this.advancedFilterData.periodeEnd = event.periodeEnd ? new Date(event.periodeEnd).toLocaleDateString("fr-CA") : "";
    this.advancedFilterData.sort = event.customDropdown.value;
    this.listData.pagination.page = 1;
    this.setFirstRequest(false);
    this.getPusatResolusi();
    this.getTabs();
  }, 500);
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter = debounce(() => {
    this.advancedFilterData = {
      periodeStart: "",
      periodeEnd: "",
      sort: "desc",
      category: {
        value: "",
        name: ""
      }
    }
    this.listData.pagination.page = 1;
    this.setFirstRequest(false);
    this.addSumFilter = 0;
    this.getPusatResolusi();
    this.getTabs();
  }, 500);
  advancedFilterData = {
    periodeStart: "",
    periodeEnd: "",
    sort: "desc",
    category: {
      value: "",
      name: ""
    }
  }
  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }

  get ticketSort() {
    return [
      { name: "Terbaru", value: "desc" },
      { name: "Terlama", value: "asc" }
    ];
  }

  toCreate() {
    dataLayer("laporkan_kendala_pusat_resolusi_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
    this.$router.push("/pusat-resolusi/create");
  }

  onChangeTabs(value: string) {
    const tab: any = {
        "in_handling": "dalam_penanganan_submenu",
        "need_reply": "butuh_balasan_anda_submenu",
        "done": "selesai_submenu",
        "all": "semua_submenu"
    }
    this.listData.pagination.page = 1;
    this.setFirstRequest(true);
    this.getPusatResolusi();
    GTMCommonEvent(tab[value])
  }

  searchValue = "";
  onSearch(value: string) {
    this.searchValue = value;
    this.listData.pagination.page = 1;
    this.setFirstRequest(false);
    this.getPusatResolusi();
    this.getTabs();
  }

  clearSearch() {
    this.searchValue = "";
    this.listData.pagination.page = 1;
    this.setFirstRequest(false);
    this.getPusatResolusi();
    this.getTabs();
  }

  listData = new PusatResolusiList(new Pagination(1, 20, 0), []);
  isFirstRequest = true;
  isLoading = false;
  isError = false;
  errorCause = "";
  async getPusatResolusi() {
    MainAppController.showLoading();
    this.isError = false;
    this.errorCause = "";
    try {
      this.listData = await PusatResolusiController.getPusatResolusiList(
        new PusatResolusiListRequest({
          page: this.listData.pagination.page,
          limit: this.listData.pagination.limit,
          search: this.searchValue,
          createdDateStart: this.advancedFilterData.periodeStart,
          createdDateEnd: this.advancedFilterData.periodeEnd,
          sort: this.advancedFilterData.sort,
          status: this.activeMenu,
          category: this.advancedFilterData.category.value
        })
      );
    } catch (err) {
      this.isError = true;
      this.errorCause = parsingErrorResponse(err).type;
    } finally {
      MainAppController.closeLoading();
    }
  }

  onRequest() {
    this.getPusatResolusi();
    this.getTabs();
  }

  setFirstRequest(status: boolean) {
    this.isFirstRequest = status;
  }

  toPageV3(value: number) {
    if (value === this.listData.pagination.page) {
      return;
    }
    this.listData.pagination.page = value;
    this.getPusatResolusi();
  }
  
}
