
import { prop, Vue } from "vue-class-component";
import {
  checkRolePermission,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { PusatResolusiListData } from "@/domain/entities/PusatResolusi";
import { PUSAT_RESOLUSI } from "@/app/infrastructures/misc/RolePermission";
import { statusData } from "./modules";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";

class Props {
  data = prop<PusatResolusiListData>({
    default: new PusatResolusiListData(),
    type: Object
  });
}

export default class PusatResolusiCard extends Vue.with(Props) {
  // permission
  get ableToViewDetail() {
    return checkRolePermission(PUSAT_RESOLUSI.DETAIL);
  }

  formatDate(date: string): string {
    return formatDate(date);
  }

  get statusData() {
    return statusData(this.data.rcStatus);
  }

  toDetail() {
    dataLayer("pusat_resolusi_lihat_detail", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
    this.$router.push(`/pusat-resolusi/detail?params=${this.data.rcTicketNoGenesis}`);
  }
}
