import { PusatResolusiListRequest, CheckValidationRequest, ModalStatusRequest, PusatResolusiStatusRequest } from "@/data/payload/api/PusatResolusiApiRequest";

export class PusatResolusiEndpoints {
    getPusatResolusiList(params: PusatResolusiListRequest): string {
        return `hydra/v1/resolution-center?${params.toQueryString()}`;
    }

    getPusatResolusiStatus(params: PusatResolusiStatusRequest): string {
        return `hydra/v1/resolution-center/status?${params.toQueryString()}`;
    }

    getCategories(): string {
        return `hydra/v1/resolution-center/category`;
    }

    checkValidationStatus(params: CheckValidationRequest): string {
        return `hydra/v1/resolution-center/check-stt?${params.toQueryString()}`;
    }

    createPusatResolusi(): string {
        return `hydra/v1/resolution-center`;
    }

    getDetailPusatResolusi(ticketId: string): string {
        return `hydra/v1/resolution-center/detail?ticket_no_genesis=${ticketId}`;
    }

    modalStatusPusatResolusi(params: ModalStatusRequest): string {
        return `hydra/v1/resolution-center/status-report?${params.toQueryString()}`;
    }

    getDetailForumPusatResolusi(id: string): string {
        return `hydra/v1/discussion-forum?${id}`;
    }

    createForumPusatResolusi(): string {
        return `hydra/v1/discussion-forum`;
    }
}
