
import { Vue, Options, prop } from "vue-class-component";
import { PusatResolusiController } from "@/app/ui/controllers/PusatResolusiController";
import {
  DetailForumPusatResolusi,
  ForumPusatResolusiList,
  PusatResolusiCommentData,
  PusatResolusiThreadData
} from "@/domain/entities/PusatResolusi";
import { formatDate, parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import Icons from "@/app/ui/components/icons/index.vue";
import DividerOthersThreads from "./divider-others-threads.vue";
import { CreateForumPusatResolusiRequest } from "@/data/payload/api/PusatResolusiApiRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";

class Props {
  data = prop<DetailForumPusatResolusi>({
    required: true
  });
  dataForum = prop<ForumPusatResolusiList>({
    required: true
  });
  caseId = prop<string>({
    required: true
  });
  isSolved = prop<boolean>({
    default: false,
    type: Boolean
  });
  isActorId = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  components: {
    Icons,
    DividerOthersThreads
  },
  emits: ["clickImage"]
})
export default class Thread extends Vue.with(Props) {
  
  imgName: any = [];
  fileName: any = [];
  rows: any = "2";
  maxSize = 5;
  maxFileImg = 3;
  maxSizeFile = 16;
  maxFileAttach = 2;
  errImg = false;
  errFile = false;

  form = {
    caseId: "",
    message: "",
    attachmentImage: [] as any,
    attachmentFile: [] as any
  };

  convertImage(file: any) {
    if(this.imgName.length <= this.maxFileImg - 1) {
      if(file.target.files[0].size < 1024 * 1024 * 5) {
        this.imgName.push(URL.createObjectURL(file.target.files[0]))
        this.form.attachmentImage.push(file.target.files[0])
        this.rows = "14";
        this.errImg = false;
      } else {
        this.errImg = true
      }
    } else {
      this.errImg = true
    }
  }

  convertFile(file: any) {
    if(this.fileName.length <= this.maxFileAttach - 1) {
      if(file.target.files[0].size < 1024 * 1024 * 5) {
        this.fileName.push(URL.createObjectURL(file.target.files[0]))
        this.form.attachmentFile.push(file.target.files[0])
        this.rows = "14";
        this.errFile = false;
      } else {
        this.errFile = true
      }
    } else {
      this.errFile = true
    }
  }

  async onSubmit() {
    MainAppController.showLoading();
    try {
      const save = await PusatResolusiController.createForumPusatResolusi(new CreateForumPusatResolusiRequest({
        caseId: this.caseId,
        message: this.form.message,
        "imageFile_1": this.form.attachmentImage[0] ? this.form.attachmentImage[0] : "",
        "imageFile_2": this.form.attachmentImage[1] ? this.form.attachmentImage[1] : "",
        "imageFile_3": this.form.attachmentImage[2] ? this.form.attachmentImage[2] : "",
        "attachment_1": this.form.attachmentFile[0] ? this.form.attachmentFile[0] : "",
        "attachment_2": this.form.attachmentFile[1] ? this.form.attachmentFile[1] : ""
      }))
      if (save.success) {
        MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Kendala Berhasil Dikirim",
          message: "Tim kami akan memproses kendala Anda",
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "image-modal-success"
        })
      );
      }
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          "Gagal menambahkan komentar",
          () => this.onSubmit()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  get ticketID(): any {
    return this.$route.query.params;
  }

  onCloseSuccess() {
    MainAppController.closeMessageModal();
    window.location.reload();
  }

  formatDate(date: string) {
    return formatDate(date);
  }

  deleteImage(img: any) {
    const index = this.imgName.indexOf(img);
    if (index !== -1) {
      this.imgName.splice(index, 1);
      this.form.attachmentImage.splice(index, 1);
    }
    if(this.form.attachmentImage.length === 0 && this.form.attachmentFile.length === 0) {
      this.rows = "4";
    }
  }

  deleteFile(file: any) {
    const index = this.fileName.indexOf(file);
    if (index !== -1) {
      this.fileName.splice(index, 1);
      this.form.attachmentFile.splice(index, 1);
    }
    if(this.form.attachmentFile.length === 0 && this.form.attachmentImage.length === 0) {
      this.rows = "4";
    }
  }

  imagePreview = false;
  image = "";
  onPreview(image: string) {
    this.image = image;
    this.imagePreview = true;
  }

  images = [] as any;
  get imagePreviews() {
    this.dataForum.data.forEach(detail => 
      detail.dfImageFiles.forEach((item: any) => {
        if(item !== '') {
          this.images.push({
            image: item,
            url: item
          }) 
        }
      })
    )
    return this.images;
  }
}
