import { container } from "tsyringe";
import ApiService from "@/app/infrastructures/services/ApiService";
import { PusatResolusiRepository } from "@/app/infrastructures/repositories/api/PusatResolusiRepository";
import { PusatResolusiMapper } from "@/data/persistences/mappers/PusatResolusiMapper";
import { PusatResolusiEndpoints } from "@/app/infrastructures/endpoints/hydra/PusatResolusiEndpoints";
import { PusatResolusiPresenter } from "@/app/ui/presenters/PusatResolusiPresenter";

export class PusatResolusiComponent {
  public static init() {
    container.register<PusatResolusiRepository>(
        PusatResolusiRepository,
      {
        useFactory: d => {
          return new PusatResolusiRepository(
            d.resolve(ApiService),
            d.resolve(PusatResolusiMapper),
            d.resolve(PusatResolusiEndpoints)
          );
        }
      }
    );
    container.register<PusatResolusiMapper>(PusatResolusiMapper, {
      useClass: PusatResolusiMapper
    });
    container.register<PusatResolusiPresenter>(
        PusatResolusiPresenter,
      {
        useFactory: d => {
          return new PusatResolusiPresenter(
            d.resolve(PusatResolusiRepository)
          );
        }
      }
    );
  }
}
