import ApiService from "@/app/infrastructures/services/ApiService";
import { PusatResolusiRepositoryInterface } from "@/data/persistences/repositories/contracts/PusatResolusiRepositoryInterface";
import { PusatResolusiMapper } from "@/data/persistences/mappers/PusatResolusiMapper";
import { PusatResolusiEndpoints } from "@/app/infrastructures/endpoints/hydra/PusatResolusiEndpoints";
import { PusatResolusiList, PusatResolusiStatusData, PusatResolusiCategoryData, CheckValidationData, DetailPusatResolusi, PusatResolusiModalStatusData, DetailForumPusatResolusi, ForumPusatResolusiList } from "@/domain/entities/PusatResolusi";
import { PusatResolusiListRequest, CheckValidationRequest, CreatePusatResolusiRequest, ModalStatusRequest, CreateForumPusatResolusiRequest, PusatResolusiStatusRequest } from "@/data/payload/api/PusatResolusiApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
export class PusatResolusiRepository implements PusatResolusiRepositoryInterface {
  private service: ApiService;
  private mapper: PusatResolusiMapper;
  private endpoints: PusatResolusiEndpoints;

  constructor(
    service: ApiService,
    mapper: PusatResolusiMapper,
    endpoints: PusatResolusiEndpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  
  async getPusatResolusiList(
    params: PusatResolusiListRequest
  ): Promise<PusatResolusiList> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getPusatResolusiList(params)
    );

    return this.mapper.convertPusatResolusiListFromApi(res);
  }

  async getPusatResolusiStatus(params: PusatResolusiStatusRequest): Promise<PusatResolusiStatusData> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getPusatResolusiStatus(params)
    );

    return this.mapper.convertPusatResolusiStatustFromApi(res);
  }

  async getCategories(): Promise<PusatResolusiCategoryData> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getCategories()
    );

    return this.mapper.convertPusatResolusiCategoryFromApi(res);
  }

  async checkValidationStatus(
    params: CheckValidationRequest
  ): Promise<CheckValidationData> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.checkValidationStatus(params)
    );

    return this.mapper.convertCheckValidationFromApi(res);
  }

  async getModalStatusPusatResolusi(
    params: ModalStatusRequest
  ): Promise<PusatResolusiModalStatusData> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.modalStatusPusatResolusi(params)
    );

    return this.mapper.convertModalStatusFromApi(res);
  }

  async createPusatResolusi(payload: CreatePusatResolusiRequest): Promise<ResponsePayload> {
    const res = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.createPusatResolusi(),
      {},
      payload as CreatePusatResolusiRequest
    );
    return this.mapper.convertRequestCreatePusatResolusiDataFromApi(res);
  }

  async getDetailPusatResolusi(ticketId: string): Promise<DetailPusatResolusi> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getDetailPusatResolusi(ticketId)
    );

    return this.mapper.convertDetailPusatResolusiFromApi(res);
  }

  async createForumPusatResolusi(payload: CreateForumPusatResolusiRequest): Promise<ResponsePayload> {
    const res = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.createForumPusatResolusi(),
      {},
      payload as CreateForumPusatResolusiRequest
    );
    return this.mapper.convertRequestCreateForumPusatResolusiDataFromApi(res);
  }

  async getDetailForumPusatResolusi(id: string): Promise<ForumPusatResolusiList> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getDetailForumPusatResolusi(id)
    );

    return this.mapper.convertForumPusatResolusiListFromApi(res);
  }

}
