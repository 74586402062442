export const statusData = (status: string) => {
  let data;
  switch (status) {
    case "need_reply":
      data = {
        label: "Butuh Balasan Anda",
        textColor: "text-gray-lp-300",
        backgroundColor: "bg-gray-lp-200"
      };
      break;
    case "in_handling":
      data = {
        label: "Dalam Penanganan",
        textColor: "text-yellow-lp-100",
        backgroundColor: "bg-yellow-lp-200"
      };
      break;
    case "done":
      data = {
        label: "Selesai",
        textColor: "text-green-lp-400",
        backgroundColor: "bg-green-lp-300"
      };
      break;
  }
  return data;
};
