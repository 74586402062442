
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import {
  convertDecimalWithComma,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { PusatResolusiController } from "@/app/ui/controllers/PusatResolusiController";
import {
  PusatResolusiCardData,
  PusatResolusiThreadData, 
  DetailPusatResolusi,
  ForumPusatResolusiList
} from "@/domain/entities/PusatResolusi";
import { statusData } from "../modules";
import Thread from "./thread.vue";
import DividerOthersThreads from "./divider-others-threads.vue";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { AccountController } from "@/app/ui/controllers/AccountController";

@Options({
  components: {
    DetailLayout,
    Thread,
    DividerOthersThreads
  }
})
export default class DetailPage extends Vue {
  mounted() {
    this.fetchDetailData();
  }

  get ticketID(): any {
    return this.$route.query.params;
  }

  toReply() {
    const reply: any = document.getElementById("reply");
    const detail: any = document.getElementById("detailLayoutData");
    detail?.scroll({
      top: reply.offsetTop - 25,
      behavior: "smooth"
    });
  }

  toThread() {
    const thread: any = document.getElementById(
      `divider-threads-${this.isExpand ? "expand" : "hide"}`
    );
    const detail: any = document.getElementById("detailLayoutData");
    detail?.scroll({
      top: thread.offsetTop - (this.isExpand ? 70 : 25),
      behavior: "smooth"
    });
  }

  get thread(): PusatResolusiThreadData {
    return PusatResolusiController.thread;
  }
  get disabledThread() {
    return !this.thread.text;
  }
  threadData: Array<any> = [];

  // expand for threads more than 2
  isExpand = false;
  onExpand() {
    this.isExpand = true;
  }
  onHide() {
    this.isExpand = false;
  }

  get statusData() {
    return statusData(this.detailTicket.rcStatus);
  }

  isActorId = false;
  get dataProfile() {
    const name = AccountController.accountData.name
    if(this.detailTicket.rcCreatedName === name) {
      this.isActorId = true
    } else {
      this.isActorId = false
    }
    return this.isActorId;
  }

  // route navigatiion
  goBack() {
    this.$router.push("/pusat-resolusi");
  }

  // Format Date
  formatDate(date: string) {
    return formatDate(date);
  }

  convertDecimalWithComma(value: any) {
    return convertDecimalWithComma(value, 2);
  }

  //   image preview
  imagePreview = false;
  image = "";
  onPreview(image: string) {
    this.image = image;
    this.imagePreview = true;
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  detailTicket = new DetailPusatResolusi();
  isLoading = false;
  isError = false;
  errorCause = "";
  caseId = "";
  isSolved = false;
  paramsForum = '';
  async fetchDetailData() {
    this.isLoading = true;
    try {
      this.detailTicket = await PusatResolusiController.getDetailPusatResolusi(this.ticketID);
      this.caseId = String(this.detailTicket.rcId)
      if(this.detailTicket.rcStatus === "done") {
        this.isSolved = true
      }
      if(this.caseId) {
        this.paramsForum = `case_id=${this.caseId}`
      } else {
        this.paramsForum = `ticket_no_genesis=${this.ticketID}`
      }
      this.fetchDetailForumData();
      this.isError = false;
    } catch (error) {
      this.errorCause = parsingErrorResponse(error).type;
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }


  // list data forum
  threadList = new ForumPusatResolusiList([]);
  async fetchDetailForumData() {
    this.isLoading = true;
    try {
      this.threadList = await PusatResolusiController.getDetailForumPusatResolusi(this.paramsForum);
      this.isError = false;
    } catch (error) {
      this.errorCause = parsingErrorResponse(error).type;
    } finally {
      this.isLoading = false;
    }
  }

  get imagePreviews() {
    return this.detailTicket.rcImageFiles.map((item: any) => {
      return {
        image: item,
        url: item
      }
    });
  }

  get checkIsPhotosExist() {
    return this.detailTicket.rcImageFiles.every((item: any) => !item);
  }

  get checkIsAttachmentExist() {
    return this.detailTicket.rcAttachment.every((item: any) => !item);
  }
}
