
import { defineAsyncComponent } from "vue";
import { Vue, prop, Options } from "vue-class-component";
const ArrowDown = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/arrow-down.vue")
});
const ArrowUp = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/arrow-up.vue")
});
const Info = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/info.vue")
});
const CargoPlane = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/cargo-plane.vue")
});
const CargoTruck = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/cargo-truck.vue")
});
const CargoTrain = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/cargo-train.vue")
});
const Transit = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/transit.vue")
});
const Misroute = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/misroute.vue")
});
const Shortland = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/shortland.vue")
});
const StiDest = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/sti-dest.vue")
});
const StiDestSc = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/sti-dest-sc.vue")
});
const Delivery = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/delivery.vue")
});
const Dex = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/dex.vue")
});
const PodDex = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/pod-dex.vue")
});
const Ellipsis = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/ellipsis.vue")
});
const FileChart = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/file-chart.vue")
});
const FileDownload = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/file-download.vue")
});
const DownloadNew = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/download-new.vue")
});
const Bkd = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/bkd.vue")
});
const Shipment = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/shipment.vue")
});
const StiSc = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/sti-sc.vue")
});
const Sti = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/sti.vue")
});
const Bagging = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/bagging.vue")
});
const Cargo = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/cargo.vue")
});
const WhiteTrash = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/white-trash.vue")
});
const GreyPlus = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/grey-plus.vue")
});
const IconX = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/icon-x.vue")
});
const CloudUpload = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/cloud-upload.vue")
});
const Attachment = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/attachment.vue")
});
const CycleTime = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/cycle-time.vue")
});
const Reload = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/reload.vue")
});
const AutoCopy = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/auto-copy.vue")
});
const ChevronUpDown = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/chevron-up-down.vue")
});
const ChevronDownUp = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/chevron-down-up.vue")
});
const TurnLeft = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/turn-left.vue")
});
const PrintSummary = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/print-summary.vue")
});
const Printer = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/printer.vue")
});
const CheckCircle = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/check-circle.vue")
});
const Hal = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/hal.vue")
});
const Rejected = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/rejected.vue")
});
const Eye = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/eye.vue")
});
const Invisible = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/invisible.vue")
});
const XCircle = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/x-circle.vue")
});
const ShortcutBookingManual = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/shortcut-booking-manual.vue")
});
const ShortcutBookingClient = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/shortcut-booking-client.vue")
});
const ShortcutBookingShipment = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/shortcut-booking-shipment.vue")
});
const ShortcutBookingInstant = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/shortcut-booking-instant.vue")
});
const ShortcutBookingPpob = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/shortcut-booking-ppob.vue")
});
const BkdToSti = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/bkd-to-sti.vue")
});
const BookingRetail = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/booking-retail.vue")
});
const BookingClient = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/booking-client.vue")
});
const BookingApp = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/booking-app.vue")
});
const BookingInstant = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/booking-instant.vue")
});
const CustomProcess = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/custom-process.vue")
});
const Exclamation = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/exclamation.vue")
});
const OutgoingCurrentStt = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/outgoing-current-stt.vue")
});
const IncomingCurrentStt = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/incoming-current-stt.vue")
});
const Timer = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/timer.vue")
});
const InputManifestDashboard = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/input-manifest-dashboard.vue")
});
const InvalidDashboard = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/invalid-dashboard.vue")
});
const IncomingOutgoing = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/incoming-outgoing.vue")
});
const STTPaidUnpaid = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/stt-paid-unpaid.vue")
});
const Backlog = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/backlog.vue")
});
const Hanging = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/hanging.vue")
});
const Whatsapp = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/whatsapp.vue")
});
const Ascending = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/ascending.vue")
});

const Descending = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/descending.vue")
});
const ProgressiveCommission = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/progressive-commission.vue")
});
const CodrejDex = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/codrej-dex.vue")
});
const Check = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/check.vue")
});
const Lock = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/lock.vue")
});
const Clock = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/clock.vue")
});
const PlusCircle = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/plus-circle.vue")
});
const Ship = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/ship.vue")
});
const BillAdd = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/bill-add.vue")
});
const ClaimPlus = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/claim-plus.vue")
});
const CheckWhite = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/check-white.vue")
});
const ExclamationWhite = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/exclamation-white.vue")
});
const ShieldWhite = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/shield-white.vue")
});
const TaskWhite = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/task-white.vue")
});
const TimeWhite = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/time-white.vue")
});
const XWhite = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/x-white.vue")
});
const PictureFrame = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/picture-frame.vue")
});
const Delete = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/delete.vue")
});
const Track = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/track.vue")
});
const CargoConfiguration = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/cargo-configuration.vue")
});
const PlusSquare = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/plus-square.vue")
});
const Plane = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/plane.vue")
});
const TrashConcentrated = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/trash-concentrated.vue")
});
const WarningCircle = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/warning-circle.vue")
});
const PencilEdit = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/pencil-edit.vue")
});
const WhatsAppOutline = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/whatsapp-outline.vue")
});
const Chain = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/chain.vue")
});
const IconProfile = defineAsyncComponent({
  loader: () => import("@/app/ui/components/icons/modules/icon-profile.vue")
});
const IconLocationPin = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/icons/modules/icon-location-pin.vue")
});
class Props {
  name = prop<string>({
    default: "",
    type: String
  });
  fill = prop<boolean>({
    default: false,
    type: Boolean
  });
  status = prop<boolean>({
    default: false,
    type: Boolean
  });
}
@Options({
  components: {
    ArrowDown,
    ArrowUp,
    Info,
    CargoPlane,
    CargoTruck,
    CargoTrain,
    Transit,
    Misroute,
    Shortland,
    StiDest,
    StiDestSc,
    Delivery,
    Dex,
    PodDex,
    Ellipsis,
    FileChart,
    FileDownload,
    Bkd,
    Shipment,
    StiSc,
    Sti,
    Bagging,
    Cargo,
    WhiteTrash,
    GreyPlus,
    IconX,
    CloudUpload,
    Attachment,
    CycleTime,
    Reload,
    AutoCopy,
    ChevronUpDown,
    ChevronDownUp,
    TurnLeft,
    PrintSummary,
    Printer,
    CheckCircle,
    Hal,
    Rejected,
    Eye,
    Invisible,
    XCircle,
    ShortcutBookingManual,
    ShortcutBookingClient,
    ShortcutBookingShipment,
    ShortcutBookingInstant,
    ShortcutBookingPpob,
    BkdToSti,
    BookingRetail,
    BookingClient,
    BookingApp,
    BookingInstant,
    CustomProcess,
    Exclamation,
    OutgoingCurrentStt,
    IncomingCurrentStt,
    Timer,
    InputManifestDashboard,
    InvalidDashboard,
    IncomingOutgoing,
    STTPaidUnpaid,
    Backlog,
    Hanging,
    Whatsapp,
    Ascending,
    Descending,
    ProgressiveCommission,
    DownloadNew,
    CodrejDex,
    Check,
    Lock,
    Clock,
    PlusCircle,
    Ship,
    BillAdd,
    ClaimPlus,
    CheckWhite,
    ExclamationWhite,
    ShieldWhite,
    TaskWhite,
    TimeWhite,
    XWhite,
    PictureFrame,
    Delete,
    Track,
    CargoConfiguration,
    PlusSquare,
    Plane,
    TrashConcentrated,
    WarningCircle,
    PencilEdit,
    WhatsAppOutline,
    Chain,
    IconProfile,
    IconLocationPin,
  }
})
export default class Icons extends Vue.with(Props) {}
