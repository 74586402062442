
import { Options, prop, Vue } from "vue-class-component";

class Props {
  isExpand = prop<boolean>({
    default: false,
    type: Boolean
  });
  isHide = prop<boolean>({
    default: false,
    type: Boolean
  });
  countSeeMore = prop<number>({
    default: 0,
    type: Number
  });
}
@Options({
  emits: ["expand", "hide"]
})
export default class extends Vue.with(Props) {
  onExpand() {
    this.$emit("expand");
  }
  onHide() {
    this.$emit("hide");
  }
}
